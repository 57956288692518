import { module } from "modujs";
import Splide from "@splidejs/splide";

export default class extends module {
  constructor(m) {
    super(m)

    // Elements
    this.$el = this.el
    this.$splide = this.$("splide")[0] // [0] означает первый элемент в модуле, без него массив всех элементов внутри модуля
    this.$decor = document.querySelector(".t-slider__pages-decor")  // [0] означает первый элемент в модуле, без него массив всех элементов внутри модуля

    this.$nav = this.$("nav")

    // Events
    this.events = {
      click: {
        "nav": "changeSlide"
      }
    }
  }

  init() {

    this.options = {
      // Desktop on down
      perPage: 1,
      perMove: 1,
      focus: 0, // 0 = left and 'center' = center
      type: 'slide', // 'loop' or 'slide'
      arrows: false,
      pagination: false, // 'slider' or false
      autoplay: false,
      speed: 500, // transition speed in miliseconds
      interval: 7000,
      dragAngleThreshold: 60, // default is 30
      autoWidth: false, // for cards with differing widths
      rewind: true, // go back to beginning when reach end
      rewindSpeed: 500,
      waitForTransition: false,
      updateOnMove: true,
      trimSpace: true, // true removes empty space from end of list
      pauseOnHover: false,
      pauseOnFocus: false,
      gap: 32,
      breakpoints: {
        480: {
          gap: 16,
        },
      }
    }

    this.splide = new Splide(this.$el, this.options).mount();


    // 

    this.widthSum = 0;

    for (let i = 0; i < this.$nav.length; i++) {
      this.widthSum += this.$nav[i].getBoundingClientRect().width;
    }

    this.active()
  }

  next() {
    this.splide.next();
  }

  active() {
    this.splide.on("active", (i) => {
      this.changeSlide(i);
      // console.log(i)
    })
  }

  changeSlide(e) {
    // Получаем таргет (кнопку) по которому нажали
    const target = e.curTarget;

    const { index, slideIndex, slide } = e // Опции splide


    for (let i = 0; i < this.$nav.length; i++) {

      this.$nav[i].classList.remove('is-active');

      // Если таргет совпадает с таргетом в nav, то мы переходимуем на этот слайд

      if (this.$nav[i] === target) {
        this.splide.go(i);
        // this.$nav[i].classList.add('is-active');
        // console.log(target)
        // const width = target.getBoundingClientRect().width;
        // this.activeIndex(i, width);
        // break;
      }
      if (i === index) {
        this.$nav[i].classList.add('is-active');
      }
    }


    if (target === undefined) return

    target.classList.add('is-active');

  }

  activeIndex(index, width) {
    if (!this.$decor) return

    this.$decor.style.width = `${width}px`;
    this.$decor.style.left = `${index * width + 2}px`;
  }

  autoplay() {

  }

  destroy() {
    super.destroy();
    this.splide.destroy();
  }
}