import { module } from "modujs";

export default class Numeric extends module {
  static NUM = 0

  constructor(m) {
    super(m);

    this.$el = this.el
    this.$count = this.$("count")[0]

    this.$template = document.querySelector("[data-numeric='template']");
    this.$list = this.$template.content.querySelector("[data-numeric='list']");
  }

  init() {
    if (!this.$count) return
    this.$count.innerHTML = `(${this.$list.children.length})`;
  }

}