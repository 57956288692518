import { module } from "modujs";

export default class extends module {
  constructor(m) {
    super(m);

    this.$el = this.el;
    this.$item = this.$("item")[0];
    this.$content = this.$("content")[0];

  }

  init() {
    if (this.$item === undefined || this.$content === undefined) return

    this.$content.append(this.$item)

    const splide = this.$content.closest("[data-module-splide]")


    if (splide !== undefined) {
      this.call("update", { target: splide }, "Splide")
    }

  }



  destroy() {
    super.destroy();
  }
}
