import { module } from "modujs";
import Splide from "@splidejs/splide";
import { isMobile } from "../app";

export default class extends module {
  constructor(m) {
    super(m)


    this.onResizeBind = this.onResize.bind(this)

    // Elements
    this.$el = this.el
    this.$splide = this.$("splide")[0]
    this.$prev = this.$("prev")[0]
    this.$next = this.$("next")[0]

    // Добавить [data-splide-arrow] к компоненту чтобы появились стрелки навигации
    this.$arrows = this.getData("arrows") !== null ? "slider" : false
    this.$gap = this.getData("gap") || 16
    this.$speed = this.getData("speed") || 600

    // Events    
  }

  init() {



    this.options = {
      // Desktop on down
      grabCursor: true,
      gap: this.$gap,
      perPage: 3,
      perMove: 1,
      focus: 0, // 0 = left and 'center' = center
      type: 'slide', // 'loop' or 'slide'
      arrows: this.$arrows, // 'slider' or false
      pagination: false, // 'slider' or false
      speed: this.$speed, // transition speed in miliseconds
      dragAngleThreshold: 60, // default is 30
      autoWidth: true, // for cards with differing widths
      // rewind: true, // go back to beginning when reach end
      // rewindSpeed: 400,
      waitForTransition: false,
      updateOnMove: true,
      trimSpace: true, // true removes empty space from end of list
    }

    this.bindEvents();
    this.onResize();

    this.splide = new Splide(this.$el, this.options).mount();

  }

  onResize() {

    if (isMobile) {
      this.options.arrows = false;
    } else {
      this.options.arrows = this.$arrows;
    }

  }

  bindEvents() {
    let timeOutFunctionId;
    window.addEventListener('resize', () => { clearTimeout(timeOutFunctionId); timeOutFunctionId = setTimeout(this.onResizeBind, 100) })
  }

  update(el) {
    if (el.target == this.splide.root) {
      this.splide.refresh();
    }
  }

  unbindEvents() {
    window.removeEventListener('resize', this.onResizeBind)
  }

  destroy() {
    super.destroy();
    this.unbindEvents();
    this.splide.destroy();
  }
}