import modular from 'modujs';
import * as modules from './modules';
import { debounce } from './utils/tickers';
import { ENV, CUSTOM_EVENT, CSS_CLASS } from './config'

const app = new modular({
  modules,
});


const $mobile = window.matchMedia('(max-width: 767px)')

export const isMobile = $mobile.matches


function init() {

  app.init(app);

}

////////////////
// Global events
////////////////
function bindEvents() {

  // Resize event
  const resizeEndEvent = new CustomEvent(CUSTOM_EVENT.RESIZE_END)
  window.addEventListener(
    "resize",
    debounce(() => {
      window.dispatchEvent(resizeEndEvent)
    }, 200, false)
  )
  window.addEventListener(
    "resize",
    onResize
  )
}

function onResize() {
  setViewportSizes()
}

function setViewportSizes() {

  // Document styles
  const documentStyles = document.documentElement.style;

  // Viewport width
  const vw = document.body.clientWidth * 0.01;
  documentStyles.setProperty('--vw', `${vw}px`);

  // Return if browser supports vh, svh, dvh, & lvh
  if (ENV.SUPPORTS_VH) {
    return
  }

  // Viewport height
  const svh = document.documentElement.clientHeight * 0.01;
  documentStyles.setProperty('--svh', `${svh}px`);

  const dvh = window.innerHeight * 0.01;
  documentStyles.setProperty('--dvh', `${dvh}px`);

  if (document.body) {
    const fixed = document.createElement('div');
    fixed.style.width = '1px';
    fixed.style.height = '100vh';
    fixed.style.position = 'fixed';
    fixed.style.left = '0';
    fixed.style.top = '0';
    fixed.style.bottom = '0';
    fixed.style.visibility = 'hidden';

    document.body.appendChild(fixed);

    var fixedHeight = fixed.clientHeight;

    fixed.remove();

    const lvh = fixedHeight * 0.01;

    documentStyles.setProperty('--lvh', `${lvh}px`);
  }
}

////////////////
// Execute
////////////////
window.addEventListener('load', () => {
  init();
});