import { module } from "modujs";
import Splide from "@splidejs/splide";

export default class extends module {
  constructor(m) {
    super(m);

    // Elements
    this.$el = this.el;
  }

  init() {
    this.options = {
      // Desktop on down
      perPage: "auto",
      perMove: 1,
      focus: 0, // 0 = left and 'center' = center
      type: 'slide', // 'loop' or 'slide'
      arrows: false,
      pagination: false, // 'slider' or false
      autoplay: false,
      speed: 500, // transition speed in milliseconds
      interval: 7000,
      dragAngleThreshold: 60, // default is 30
      autoWidth: false, // for cards with differing widths
      rewind: true, // go back to beginning when reach end
      rewindSpeed: 500,
      waitForTransition: false,
      updateOnMove: true,
      trimSpace: true, // true removes empty space from end of list
      pauseOnHover: false,
      pauseOnFocus: false,
      gap: 16,
    };

    const figures = this.$el.querySelectorAll("figure");
    if (figures.length === 0) return; // Если нет ни одной figure, ничего не делать

    let consecutiveFigures = []; // Массив для хранения последовательных figure
    let splideList;

    figures.forEach((figure, index) => {
      if (index === 0 || figure.previousElementSibling.tagName === "FIGURE") {
        consecutiveFigures.push(figure); // Добавляем figure в массив, если она идет подряд
      } else {
        if (consecutiveFigures.length > 1) {
          // Если в массиве больше одной figure, создаем слайдер
          this.createSplide(consecutiveFigures);
        }
        consecutiveFigures = [figure]; // Начинаем новый массив для следующего блока figure
      }

      // Если это последняя figure и она часть последовательности, обрабатываем её
      if (index === figures.length - 1 && consecutiveFigures.length > 1) {


        this.createSplide(consecutiveFigures);
      }
    });
  }

  createSplide(figures) {
    const splideTrack = document.createElement("div");
    const splideList = document.createElement("div");
    const splideMain = document.createElement("div");

    splideMain.classList.add("splide");
    splideTrack.classList.add("splide__track");
    splideList.classList.add("splide__list");
    // this.$el.classList.add("splide");

    splideTrack.append(splideList);
    splideMain.append(splideTrack);
    figures[0].before(splideMain);

    figures.forEach((figure) => {
      figure.classList.add("splide__slide");
      splideList.append(figure);
    });

    this.splide = new Splide(splideMain, this.options).mount();
  }

  destroy() {
    super.destroy();
    if (this.splide) {
      this.splide.destroy();
    }
  }
}
